<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 28 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      :style="iconStyle"
      d="M22 8C22 12.4187 18.4188 16 14 16C9.58125 16 6 12.4187 6 8C6 3.58187 9.58125 0 14 0C18.4188 0 22 3.58187 22 8ZM13.0688 22.45L11 19H17L14.9312 22.45L17.0125 30.1938L19.4813 20.1187C24.3063 20.8687 28 25.0437 28 30.0812C28 31.1375 27.1375 32 26.0812 32H1.92C0.859375 32 0 31.1375 0 30.0812C0 25.0437 3.69313 20.8687 8.51875 20.1187L10.9875 30.1938L13.0688 22.45Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  props: {
    height: {
      type: String,
      default: '32',
    },
    width: {
      type: String,
      default: '28',
    },
    color: {
      type: String,
      default: 'primary-green',
    },
  },
  computed: {
    /**
     * Icon Style
     * @param {void}
     * @returns {string} - Color
     * @description Assigns the specific color to the icon
     */
    iconStyle: function () {
      return {
        fill: ['$' + this.color, `var(--${this.color})`],
      }
    },
  },
}
</script>
